body {
  background-image: url("https://github.com/tojibaceo/tojibaceo/blob/main/ezgif.com-gif-maker_1.gif");
  background: #000000;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img{
  width: 100%;
}
p {
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  font-weight: bold;
}
a{
  color: yellow;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
